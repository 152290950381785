<template>
  <div class="login-container">
    <el-form
      ref="loginForm"
      :model="loginForm"
      label-position="left"
      class="login-form"
      autocomplete="on"
    >
      <div class="title-container">
        <h3 class="title">登录</h3>
      </div>

      <el-form-item prop="username">
        <el-input
          ref="username"
          v-model="loginForm.username"
          placeholder="用户名"
          name="username"
          type="text"
          tabindex="1"
          autocomplete="on"
          @keyup.enter.native="handleLogin"
        />
      </el-form-item>

      <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
        <el-form-item prop="password">
          <el-input
            ref="password"
            v-model="loginForm.password"
            type="password"
            placeholder="密码"
            name="password"
            tabindex="2"
            autocomplete="on"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
        </el-form-item>
      </el-tooltip>


<!--      <el-form-item prop="captchaCode" class="captcha-code">-->
<!--        <el-input-->
<!--          ref="username"-->
<!--          v-model="loginForm.captchaCode"-->
<!--          placeholder="验证码"-->
<!--          name="captchaCode"-->
<!--          type="text"-->
<!--          tabindex="3"-->
<!--          autocomplete="off"-->
<!--          @keyup.enter.native="handleLogin"-->
<!--        />-->
<!--        <div v-if="loginForm.captchaUrl" class="captcha-code-img">-->
<!--          <a @click="showCaptcha">-->
<!--            <img :src="loginForm.captchaUrl" />-->
<!--          </a>-->
<!--        </div>-->
<!--      </el-form-item>-->
      <el-button
        :loading="loading"
        type="primary"
        style="width: 100%; margin-bottom: 5px"
        @click.native.prevent="handleLogin"
      >Login</el-button>
      <a class="update-password" style="font-size: 12px; float: right;" @click="handleForgetPassword">忘记密码</a>
    </el-form>

    <el-dialog @close="handleUserCancel" :visible.sync="userFormVisible" :close-on-click-modal="false" title="忘记密码">
      <el-form ref="userForm" :model="userForm" :rules="userFormRules" label-width="80px">
        <el-form-item label="账号" prop="username">
          <el-input v-model="userForm.username" placeholder="请输入账号" clearable/>
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="userForm.password" placeholder="请输入密码" clearable/>
        </el-form-item>

        <el-form-item label="确认密码" prop="repassword">
          <el-input type="password" v-model="userForm.repassword" placeholder="请输入确认密码" clearable
                    @keyup.enter.native="updateUserSubmit"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="handleUserCancel">取消</el-button>
        <el-button :loading="userLoading" type="primary" @click.native="updateUserSubmit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import Api from '@/api/user-admin.js';
export default {
  data() {
    let validateUsername = (rule, value, callback) => {
      this.axios.post(Api.exitAdminUserName,{username: value}).then(res => {
        if (res < 1) {
          callback(new Error("账号不存在"));
        } else {
          callback();
        }
      });
    };
    const validatePass = (rule, value, callback) => {
      if (this.userForm.repassword !== null) {
        // 对第二个密码框单独验证
        this.$refs.userForm.validateField('repassword')
      }
      callback()
    }
    const validatePassCheck = (rule, value, callback) => {
      if (value !== this.userForm.password) {
        callback(new Error('两次密码输入不一致'))
      } else {
        callback()
      }
    }
    return {
      userLoading: false,
      userFormRules: {
        password: [
          {required: true, message: "密码不能为空!", trigger: "blur"},
          { validator: validatePass, trigger: 'blur' }],
        repassword: [
          {required: true, message: "请再次输入密码!", trigger: "blur"},
          { validator: validatePassCheck, trigger: 'blur' }],
        username: [
          {required: true, message: "账号不能为空!", trigger: "blur"},
          { validator: validateUsername, trigger: 'blur' }
        ],
      },
      userFormVisible: false,
      userForm: {
        username: null,
        password: null,
        repassword: null
      },
      loginForm: {
        username: "",
        password: "",
        captchaId: "",
        captchaUrl: "",
        captchaCode: "",
      },
      capsTooltip: false,
      loading: false,
      redirect: undefined,
      otherQuery: {},
    };
  },
  watch: {
    $route: {
      handler(route) {
        const { query } = route;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.loginForm.username === "") {
      this.$refs.username.focus();
    } else if (this.loginForm.password === "") {
      this.$refs.password.focus();
    }
    // this.showCaptcha();
  },
  methods: {
    // 提交按钮
    updateUserSubmit() {
      const that = this;
      const params = {...that.userForm};
      that.$refs["userForm"].validate((valid) => {
        if (valid) {
          that.userLoading = true;
          that.axios.post(Api.updatePassword, params).then(res => {
            that.$message.success("修改成功");
            that.userFormVisible = false;
            that.userLoading = false;
          }).finally(() => {
            that.userLoading = false;
          });
        }
      });
    },
    // 打开忘记密码页面
    handleForgetPassword(){
      this.userFormVisible = true;
    },
    // 取消按钮
    handleUserCancel() {
      this.$nextTick(()=> {
        this.userForm = {
          username: null,
          password: null,
          repassword: null
        };
        this.$refs.userForm.resetFields();
        this.userLoading = false;
        this.userFormVisible = false;
      });
    },
    async showCaptcha() {
      try {
        // const ret = await this.axios.get("/api/captcha/request", {
        //   params: {
        //     captchaId: this.loginForm.captchaId || "",
        //   },
        // });
        // this.loginForm.captchaId = ret.captchaId;
        // this.loginForm.captchaUrl =
        //   process.env.VUE_APP_BASE_API +
        //   "/view/verifyCode/getVerifyCode?captchaId=" +
        //   this.loginForm.captchaId +
        //   "&timestamp=" +
        //   new Date().getTime();
        this.loginForm.captchaUrl =
          process.env.VUE_APP_BASE_API +
          "/view/verifyCode/getVerifyCode?timestamp=" +
          new Date().getTime();
      } catch (e) {
        this.$message.error(e.message || e);
      }
    },
    checkCapslock({ shiftKey, key } = {}) {
      if (key && key.length === 1) {
        if (
          (shiftKey && key >= "a" && key <= "z") ||
          (!shiftKey && key >= "A" && key <= "Z")
        ) {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }
      if (key === "CapsLock" && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("user/login", this.loginForm)
            .then(() => {
              this.$router.push({
                path: this.redirect || "/",
                query: this.otherQuery,
              });
              this.loading = false;
            })
            .catch((e) => {
              this.showCaptcha();
              this.loading = false;
            });
          return true;
        }
        return false;
      });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
  },
};
</script>

<style lang="scss">
.login-container {
  a:hover {
    color: #999999;
  }
  min-height: 100%;
  width: 100%;
  background-color: #fff;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;

    .captcha-code {
      & > div {
        display: flex;
        .captcha-code-img {
          // margin-left: 10px;
          img {
            height: 36px;
          }
        }
      }
    }
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: #000;
      margin: 0 auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
